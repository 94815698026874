import React from 'react';


import { Layout } from '../components';

class Document extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    }
  }

  async componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    this.document_id = params.get('id');

    const query = page_id => `
      query MyQuery {
        document(filter: {id: { eq: ${page_id} }}) {
          fileUrl
          freeDocument
          freeSample
          hasPassword
          id
          isFeatured
          keywords
          name
          title
          price
          updatedAt
          previewurl
        }
      }
    `;
    const page_id = 55481519;
    const q = query(this.document_id);

    const response = await fetch(process.env.GATSBY_DATO_CMS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${process.env.GATSBY_DATO_API_TOKEN}`
      },
      body: JSON.stringify({
        query: q,
      })
    });
    const data = await response.json();
    this.state.data = data;
  }

  render() {
    return (
      <Layout>
        <div>
          My Layout
          {JSON.stringify(this.state.data)}
          {this.test}
        </div>

      </Layout>
    );
  }
}


export default Document;

